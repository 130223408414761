import { graphql, PageProps } from "gatsby";
import React from "react";
import { SimplePage } from "../containers/SimplePage";

export default ({
  data: {
    sanitySiteSettings: { title },
  },
}: PageProps<{ sanitySiteSettings: GatsbyTypes.SanitySiteSettings }>) => {
  const description = `${title}のプライバシーポリシーについて、ご利用者様にご案内いたします。`;
  const markdown = `${title}（以下、「当サイト」といいます）では、個人情報の保護について以下の方針を定めております。

  なお、当サイトのプライバシーポリシーは、予告なく内容を変更させていただく場合がございますので、あらかじめご了承ください。

  ## アクセス解析ツールについて

  当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにCookie（クッキー）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
  `;

  return (
    <SimplePage
      title="プライバシーポリシー"
      description={description}
      markdown={markdown}
    />
  );
};

export const query = graphql`
  {
    sanitySiteSettings {
      title
    }
  }
`;
